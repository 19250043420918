@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500&family=Inter+Tight:ital,wght@0,100;0,200;1,100;1,200&family=Oxygen:wght@300;400;700&family=Roboto:wght@100&family=Ubuntu:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500&family=Inter+Tight:ital,wght@0,100;0,200;1,100;1,200&family=Manrope:wght@200;300;400;500;600;700;800&family=Oxygen:wght@300;400;700&family=Roboto:wght@100&family=Ubuntu:wght@500&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  /* background: radial-gradient(53.46% 53.46% at 50% 50%, rgba(8, 148, 71, 0.46) 0%, rgba(0, 0, 0, 0.00) 100%); */
  /* background: #000000; */
  /* background-image: url("./assets/png/main_bg5.png"); */
}
body::-webkit-scrollbar {
  width: 0.7em;
}
html{
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-track {
  background: #000000;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
}

:root {
  --primary-font: "Oxygen";
  --secondary-font: "Manrope";
  --primary-color:#28AA47;
  --secondary-color:#f8f8ff;
}

/* common styles */
.section__title{
  color: #f8f8ff;
  font-family: var(--primary-font);
  font-size: 2.3rem;
  text-align: center;

}
